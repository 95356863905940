.app-button {
    max-height: 110px;
}

#top-apps .app-button {
    max-width: 250px;
    max-height: 250px;
    box-shadow: none;
    margin: 0 .5rem;
}

#top-apps img {
    max-width: 100%;
    max-height: 100px;
}

#app-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

#app-list .app-button {
    box-shadow: none;
    max-height: initial;
}

#app-list img {
    width: 100px;
    height: auto;
}